import React from "react"
import "../css/pages/academicadvantage.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Seamless from "../components/SeamlessIframe"

const AcademicAdvantage = () => {
  return (
    <Layout language="en">
      <SEO
        title="Academic Advantage at Utah Surgical Arts"
        description="Learn more about the 2023 Academic Advantage program at Utah Surgical Arts"
        lang="en"
      />
      <div className="edu-23">
        <div className="edu-23__section">
          <div className="edu-23__container" style={{ position: "relative" }}>
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-edu-logo"
              alt="academic advantage logo"
              className="edu-23__hero--logo"
            />
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/USA-EDU-hero-image"
              alt="academic advantage logo"
              className="edu-23__hero--desktop"
            />
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/usa-edu-hero-image-mobile"
              alt="academic advantage logo"
              className="edu-23__hero--mobile"
            />
          </div>
        </div>

        <div className="edu-23__section">
          <div className="edu-23__container">
            <div className="">
              <div>
                <h1>HEALTHCARE SYMPOSIUM FOR STUDENTS — FREE TO ATTEND!</h1>
                <h2>Let's Talk About Your Future</h2>

                <p style={{ marginBottom: 0 }}>
                  <b>Here’s What You’ll Learn:</b>
                </p>
                <ul>
                  <li>
                    When and how to start planning for your healthcare career
                  </li>
                  <li>Tips on preparing for college and medical school</li>
                  <li>
                    The daily routine of a medical and dental professional
                  </li>
                  <li>What you can expect from a medical career</li>
                  <li>What it’s like to be an entrepreneur and a doctor</li>
                  <li>The many healthcare career paths you can take</li>
                </ul>
                <p>
                  In addition to learning from talented and respected medical
                  professionals, representatives from local colleges and
                  nonprofits will be available to provide more information about
                  college admissions, specific topics, and answer questions.
                </p>
              </div>
              {/* <div className="edu-23__date-time">
                <h2>DATE, TIME, & LOCATION:</h2>

                <p>
                  <span className="edu-23__blue bold">Saturday</span>
                  <br />
                  April 15, 2023
                  <br />
                  9:30 AM–1:30 PM
                </p>
                <p>
                  <span className="edu-23__blue bold">
                    Wilkinson Student Center <br />
                    Brigham Young University
                  </span>
                  <br />
                  1060 E Campus Dr, Provo, UT 84604
                  <br />
                  Rooms 3220-3222-3224
                </p>
              </div> */}
            </div>
          </div>
        </div>

        {/* <div className="edu-23__section">
        <div className="edu-23__container">
          <div className="edu-23__speakers">
            <h2>FEATURED SPEAKERS:</h2>
            <div className="edu-23__even-grid">
              <div>
                <p>
                  <span className="bold">Doctor Name DDS</span>
                  <br />
                  Oral &amp; Maxillofacial Surgeon at
                  <br />
                  Oral Surgery Specialists of Oklahoma
                </p>
                <p>
                  <span className="bold">Doctor Name DDS</span>
                  <br />
                  Oral &amp; Maxillofacial Surgeon at
                  <br />
                  Oral Surgery Specialists of Oklahoma
                </p>
              </div>
              <div>
                <p>
                  <span className="bold">Doctor Name DDS</span>
                  <br />
                  Oral &amp; Maxillofacial Surgeon at
                  <br />
                  Oral Surgery Specialists of Oklahoma
                </p>
                <p>
                  <span className="bold">Doctor Name DDS</span>
                  <br />
                  Oral &amp; Maxillofacial Surgeon at
                  <br />
                  Oral Surgery Specialists of Oklahoma
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="edu-23__apply">
          <h3>RSVP to attend the event by filling out the form below.</h3>
        </div>

        <div className="edu-23__form">
          <Seamless src="https://secureform.seamlessdocs.com/f/21a77c5211a0e7b6004b60ca229edaf0?embedded=true" />
        </div> */}
      </div>
    </Layout>
  )
}

export default AcademicAdvantage
